body {
  overflow-x: hidden;
}
input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}
p {
  color: #555;
}
.green {
  color: #16baa6;
}
.red {
  color: #ff0000;
}
.navbar-logo {
  width: 170px;
}
.form-control:focus {
  border: 1px solid darkgray;
  box-shadow: none;
}
.error-message {
  color: red;
  font-size: 12px;
}
.react-date-picker__wrapper {
  display: none;
}
.login-page {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom right, #50b849, #98dc93);
  background-repeat: no-repeat;
  .login-container {
    background: white;
    max-width: 500px;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        text-align: left;
      }
    }
  }
}

h1 {
  margin: 20px auto;
  font-size: xx-large;
}

.header {
  position: relative;
  width: 100%;
  background: #50b849;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  p {
    margin: 0;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  .button-holder {
    position: absolute;
    right: 5px;
  }
}

.card-details {
  .content {
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .card-image {
      max-width: 300px;
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
    .current-discount {
      p {
        color: #16baa6;
        text-align: center;
        font-size: 16px;
        span {
          display: block;
          font-size: 24px;
        }
      }
    }
  }
}
.add-ticket {
  .content {
    padding: 20px;
    .count-input-wrapper {
      display: flex;
      input {
        text-align: center;
        background: white;
      }
    }
  }
}
.scan-card {
  .content {
    padding: 20px;
    p {
      font-size: 16px;
      color: #555;
      span {
        display: block;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .card-number-input-wrapper {
      display: flex;
    }
  }
}

.qr-code {
  .content {
    padding: 20px;
  }
}

.new-card {
  margin-top: 20px;
  .content {
    max-width: 600px;
    margin: 10px auto;
    background: #50b849;
    padding: 20px;
    border-radius: 10px;
  }
}

.play {
  display: flex;
  p.play-name {
    flex: 1;
    text-align: left;
    font-size: 14px;
    span {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
  }
  p.play-date {
    flex: 1;
    text-align: left;
    font-size: 14px;
    span {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
  }
  p.play-time {
    flex: 1;
    text-align: left;
    font-size: 14px;
    span {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.added-ticket {
  background: #e0e0e0;
  padding: 10px;
  text-align: left;
  border: 1px solid gray;
  border-radius: 2px;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
}

.child-card {
  background: gray;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .remove-child-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
  }
  p.child-name {
    margin: 0;
    color: white;
    font-weight: normal;
    span {
      font-weight: bold;
    }
  }
  p.child-birth {
    margin: 0;
    color: white;
    font-weight: normal;
    span {
      font-weight: bold;
    }
  }
}
hr {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

dl {
  margin-bottom: 0;
}

.box {
  margin-bottom: 15px;

  .content {
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #50b849;
    border-top: none;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.page-link {
  color: #50b849;
}

.page-link:hover {
  color: #50b849;
}

.page-item.active .page-link {
  background-color: #50b849;
  border-color: #50b849;
}

.card-not-found {
  max-width: 500px;
  margin: 50px auto;
  .buttons-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 50px auto;
    .btn {
      width: 200px;
    }
  }
}

.email-verification {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  background-color: #dcdadb;
  background-image: url(../assets/pozadina.png);

  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    height: 80vh;
    width: 70%;
    background-color: #b5fda7;
    border-radius: 20%;

    p {
      color: black;
    }
  }
}
